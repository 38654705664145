/* NavBar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center align items vertically */
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    position: fixed; /* Make the navbar stick to the top */
    top: 0;
    width: 100%;
    z-index: 1000; /* Ensure the navbar is above other content */
    height: 60px; /* Define the height of the navbar */
}

.title {
    margin: 0;
    color: #ff9900;
    font-size: 26px;
}

.loginButton, .logoutButton {
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 10px; /* Add some space between buttons */
}

.loginButton:hover, .logoutButton:hover {
    background-color: #0056b3; /* Darker blue on hover */
}
.button-group {
    display: flex;
    align-items: center;
}

.btn {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 10px; /* Add some space between buttons */
    text-decoration: none; /* Remove underline from link */
    display: inline-block;
}

.btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
}