/* SportSurvey.css */
.checkbox-group {
    display: flex;
    flex-direction: column; /* Align checkboxes vertically */
    gap: 10px;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
  }
  
  .checkbox-label {
    margin-left: 5px;
    color: black; /* Set the text color to black */
    font-size: 1.2rem; /* Reduce the font size */
  }
  
  .small-checkbox .form-check-input {
    width: 1rem; /* Reduce the width of the checkbox */
    height: 1rem; /* Reduce the height of the checkbox */
  }