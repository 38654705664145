/* EmailForm.css */
.form-inline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 1.5rem;
  width: 100%;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.2s;
}

.form-control:focus {
  border-color: #f8b26a;
  outline: none;
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  color: white;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.checkbox-label {
  color: white;
  margin-left: 0.5rem;
}

.form-check-input {
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

.btn.orange {
  background-color: #f8b26a;
  border: none;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  transition: background-color 0.2s;
}

.btn.orange:hover {
  background-color: #f47e60;
}

#optional-form-inputs {
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 4px;
  margin-top: 1.5rem;
}

#optional-form-inputs .form-group {
  margin-bottom: 1rem;
}

#optional-form-inputs .form-label {
  font-weight: 500;
  color: #555;
}
.text-input {
  display: 'inline-block';
  height: '40px' !important;
  width: 'calc(100% - 180px)' !important;
}
/* Media query for mobile screens */
@media (max-width: 768px) {
  .form-label {
    font-size: 1rem !important;
  }
  .text-input{
    width: 'calc(100% - 0px)'
  }
}