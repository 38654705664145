.orange {
    color: #FFB600;
    background-color: #FFB600;
}
.text-orange {
    color: #FFB600 !important;
}
.green {
    color: #FFFFFF;
    background-color: #149255;
}
.text-green {
    color: #149255 !important;
}
.white {
    color: #FFFFFF;
    
}
.black {
    color: #000000;
    background-color: #000000;
}

.main-content {
    margin-top: 60px; /* Add top margin equal to the height of the navbar */
  }

  @media (max-width: 768px) {
    .hive-logo{
        max-height: 30px
    }
  }

