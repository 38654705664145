body {
    font-family: 'Organetto Medium', sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Organetto Medium', sans-serif;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  p {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  