.hive-logo-container {
    display: flex;
    align-items: center; /* Ensures vertical centering */
    justify-content: center; /* Centers content horizontally if needed */
    gap: 10px; /* Adds spacing between the logo and text */
}

.hive-logo {
    max-height: 100px; /* Adjust as needed */
    object-fit: cover;
}

.logo-text {
    font-weight: bold;
    display: flex;
    flex-direction: column; /* Stack "Hive" and "Sport and Social" */
    align-items: flex-start; /* Align text to the left */
}
